<template>
  <div class="flow-blue-bg-color">
    <div class="w3-sidebar flow-blue-bg-color w3-large" id="sidebar">
      <div class="w3-container w3-center w3-collapse" id="flow-side-menu-bar">
        <div class="w3-bar-block">
          <router-link v-for="item in menuItems"
                       class="w3-button w3-bar-item menu-item"
                       :key="item.name"
                       :class="item.cls"
                       :title="$t(item.name)" :to="{name: item.route}">

            <div class="menu-icon">
              <i class="fa" :class="item.icon"></i>
            </div>
            <div class="menu-name" v-bind:style="toggleMenuC">{{ $t(item.name) }}</div>
          </router-link>
        </div>
      </div>
    </div>

    <header class="flow-blue-bg-color" id="flow-header">

      <div class="w3-container zero">
        <div class="w3-col w-50pc zero">
          <h4 class="zero">
            <i class="fa fa-bars w3-margin-left cursor-pointer" id="open-nav" @click="toggleMenu"
               :title="expandMenu ? $t('collapseMenu') : $t('expandMenu')"></i>
            <span class="font-weight-bold"
                  id="company-name"> {{ siteSettings && siteSettings.viewApplicationName ? siteSettings.viewApplicationName : 'Partheas Q' }}</span>
          </h4>
        </div>
        <div class="w3-col w3-third w3-right">
          <h4 v-if="currentUser" class="w3-right w3-margin-right zero" id="current-user">
                             <span class="current-ward cursor-pointer flow-margin-right w3-ripple"
                                   v-if="sites.length > 1"
                                   @click="showSiteList = !showSiteList">
                                <span class="chosen-ward">
                                    {{ selectedSite }}
                                </span>
                                <i class="fa fa-chevron-down i-rotate" v-bind:class="showSiteList ? 'active' : ''"></i>
                                <ul class="w3-ul flow-list w3-animate-opacity site-dropdown" v-if="showSiteList">
                                    <li class="w3-ripple" v-for="site in sites" :key="site.name"
                                        @click="chooseItem(site)">{{ site.name }}</li>
                                </ul>
                            </span>
            <span v-else class="current-ward">
                                <span class="chosen-ward">{{ selectedSite }}</span>
                            </span>

            <span class="flow-margin-left flow-margin-right">|</span>

            <i class="fa fa-user-circle flow-margin-right"
               v-bind:class="currentUser.role ? currentUser.role.toLowerCase() + '-user-color' : ''">
            </i>
            <span id="\current-user-name" class="font-weight-bold" @click="goToUserPage"
                  style="cursor:pointer">{{ currentUser.name }}</span>
          </h4>
        </div>
      </div>
    </header>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "SideMenu",
  data: function () {
    return {
      expandMenu: false,
      showSiteList: false,
      currentUser: {
        name: 'shra',
        role: 'ADMIN',
        sites: []
      },
      normalMenuItems: [
        {name: 'dashboard', icon: "fa-user", route: "dashboard"},
        {name: 'home', icon: "fa-home", route: "home"}
      ],
      adminMenuItems: [
        {name: 'admin-settings', icon: "fa-user-cog", route: "admin-settings"},
        {name: 'site-settings', icon: "fa-sliders-h", route: "site-settings"},
        {name: 'kiosk-settings', icon: "fa-cogs", route: "kiosk-settings"}
        // {name: 'help', icon: "fa-regular fa-info-circle", route: "help"}
      ],
      logoutMenuItem: [
        {name: 'logout', icon: "fa-sign-out-alt", route: "logout"}
      ]
    };
  },
  methods: {
    toggleMenu: function () {
      this.expandMenu = !this.expandMenu;

      if (this.expandMenu) {
        document.getElementById("sidebar").style.width = '20%';
        document.getElementById("flow-content").style.marginLeft = '21%';

      } else {
        document.getElementById("sidebar").style.width = '4%';
        document.getElementById("flow-content").style.marginLeft = '5%';
      }

      this.$emit("menuToggled");
    },
    goToUserPage() {
      this.$router.push({name: "accountDetails"});
    },
    chooseItem: function (chosenItem) {
      this.setSelectedSite(chosenItem);
      this.$router.push({name: 'dashboard', params: {siteId: chosenItem.id}});
    },
    setSelectedSite(site) {
      this.$store.commit('sites/setCurrentSite', {id: site.id, name: site.name, address: site.address});
    }
  },
  computed: {
    selectedSite: function () {
      return this.$store.getters["sites/getCurrentSite"].name;
    },
    sites: function () {
      return this.$store.getters["sites/getSites"];
    },
    toggleMenuC: function () {
      return {
        display: this.expandMenu ? "inline-block" : "none"
      }
    },
    menuItems: function () {
      let logout = this.logoutMenuItem;
      if (!this.currentUser.role) {
        return logout;
      }

      if (this.currentUser.role === "NORMAL") {
        return this.normalMenuItems.concat(logout);
      }
      if (this.currentUser.role === "ADMIN") {
        return this.normalMenuItems.concat(this.adminMenuItems).concat(logout);
      }

      return logout;
    },
    ...mapState(['siteSettings'])
  },
  mounted() {
    this.currentUser = this.$store.getters["users/getCurrentUser"];
    this.$helper.sortByKey(this.sites, 'name');
  }
}

</script>

<style scoped>

</style>

